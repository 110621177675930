<template>
  <div v-on:keyup.enter="submitForm">
    <div class="login"></div>
    <div class="login-form">
      <el-form :model="form" ref="loginForm" class="demo-ruleForm" :rules="rules" style="width:70%">
        <div class="text-header">用户登录</div>
        <el-form-item label="账号" prop="account">
          <el-input type="text" v-model="form.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="form.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">登录</el-button>
          <a
            style="font-size: 8px;color:#3398DB;float:right;border-bottom:1px solid #3398DB;height: 28px;cursor:pointer"
            @click="goHomePath"
          >游客登录</a>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { login } from "../../service/request";
export default defineComponent({
  setup() {
    const router = useRouter();
    const loginForm = ref(null);
    const form = reactive({
      account: "",
      pass: "",
    });
    const submitForm = () => {
      let params = {
        account: form.account,
        password: form.pass,
      };
      login(params)
        .then((res) => {
          console.log("=========login:",res);
          ElMessage({
            message: "登录成功",
            type: "success",
          });
          router.push({
            path: "/",
          });
          sessionStorage.setItem("user", res.data.data.result.role);
          sessionStorage.setItem("userName", res.data.data.result.account);

        })
        .catch((err) => {
          ElMessage({
            message: "账号密码错误",
            type: "error",
          });
        });
    };

    const goHomePath = () => {
      router.push({
        path: "/",
      });
    }
    //表单验证
    const rules = {
      account: [
        {
          required: true,
          message: "请填写账号",
          trigger: "blur",
        },
      ],
      pass: [
        {
          required: true,
          message: "请填写密码",
          trigger: "blur",
        },
      ],
    };
    return {
      form,
      submitForm,
      loginForm,
      rules,
      goHomePath
    };
  },
});
</script>

<style scoped lang="less">
.el-button {
  width: 100%;
}
.text-header {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 50px;
  color: #2b85e4;
}
.login {
  width: 100vw;
  height: 100vh;
  background: #2d3a4b;
}
.login-form {
  position: absolute;
  width: 500px;
  height: 400px;
  // top: 200px;
  // right: 300px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #333;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
</style>
<style lang="less">
.login-form {
  .el-form-item__label {
    color: #000;
  }
}
</style>